@import '../../styles/helpers/index.scss';

.map {
  margin: 130px 0 0;
  padding: 90px 0 50px;

  background-image: url('../../static/map_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
    padding: 60px 0;
  }

  .section-title {
    color: #fff;
    margin: 0 0 30px;
  }
}
