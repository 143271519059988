@import '../../styles/helpers/index.scss';

.hero {
  background-image: url('../../static/hero_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  padding: 40px 0;
  color: #fff;

  .container {
    @include space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 15px;
    }

    & > div {
      // max-width: 50%;
      width: 100%;

      &:first-of-type {
        max-width: 60%;
      }

      &:last-of-type {
        max-width: 40%;
      }

      @media screen and (max-width: $tablet-sm) {
        max-width: unset !important;
        width: auto !important;
      }
    }
  }

  &__content {
    h1 {
      position: relative;
      font-weight: 600;
      font-size: 120px;
      line-height: 110%;
      z-index: 1;

      @media screen and (max-width: $tablet-sm) {
        font-size: 72px;
      }

      &::before {
        content: '';

        background-image: url('../../static/hero_ellipse.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        width: 310px;
        height: 140px;

        position: absolute;
        right: 25%;
        user-select: none;
        z-index: -1;

        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }
    }

    p {
      margin: 10px 0 70px;
      font-weight: 400;
      font-size: 36px;
      line-height: 120%;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 45px;
        font-size: 30px;
      }
    }
  }

  &__form {
    background: #fff;

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: map-get($colors, 'heading');
      padding: 0 25px 25px;
    }
  }
}

#long-distance-movers,
#local-movers {
  .hero {
    .container {
      & > div {
        &:first-of-type {
          max-width: 60%;
        }

        &:last-of-type {
          max-width: 40%;
        }
      }
    }

    h1 {
      font-size: 82px;
      line-height: 110%;
      padding-right: 25px;

      @media screen and (max-width: $tablet-sm) {
        padding: 0;
        font-size: 42px;
      }

      &::before {
        top: 25%;
      }
    }
  }
}
