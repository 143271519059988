@import '../../styles/helpers/index.scss';

.about {
  margin: 130px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .container {
    @include space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        width: auto;
      }
    }

    &.not-home {
      .section-title {
        max-width: 700px;
        margin: 0 0 40px;

        font-weight: 600;
        font-size: 64px;
        line-height: 120%;
        text-align-last: left;

        &::before {
          background-image: url('../../static/why-we_ellipse.svg');

          width: 260px;
          height: 120px;

          left: 7%;
          top: -20%;
        }
      }
    }
  }

  &__content {
    .section-title {
      margin: 0 0 60px;

      @media screen and (max-width: $tablet-sm) {
        margin: 0 0 30px;
      }

      &::before {
        background-image: url('../../static/about_ellipse.svg');

        width: 260px;
        height: 120px;

        top: -10%;
        right: 25%;
      }
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 150%;

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }
  }

  &__media {
    background-image: url('../../static/about_media.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    min-height: 600px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }
}
