@import '../../styles/helpers/index.scss';

.legal-content {
  margin: 60px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  color: #000;

  h2 {
    margin: 60px 0 30px;
    font-weight: 600;
    font-size: 40px;
    line-height: 130%;
  }

  h3 {
    font-weight: bold;
    margin: 0 0 15px;
  }

  p {
    &:not(:last-of-type) {
      margin: 0 0 15px;
    }
  }

  a {
    color: #000;
    text-decoration: underline !important;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  ol,
  ul {
    padding-left: 25px;

    li {
      margin: 0 0 15px;
    }
  }
}
