@import '../../styles/helpers/index.scss';

.page-bg {
  background-image: url('../../static/blog_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  min-height: 400px;

  @include align-center;

  .section-title {
    color: #fff;
    z-index: 1;

    &::before {
      background-image: url('../../static/services_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 0;
      top: -20%;
    }
  }
}
