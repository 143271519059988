@import '../../styles/helpers/index.scss';

.reviews {
  margin: 130px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .section-title {
    max-width: 690px;

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
    }

    &::before {
      background-image: url('../../static/reviews_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 38%;
      top: 45%;
    }
  }

  &__list {
    margin: 60px 0 0;

    &--card {
      padding: 40px 30px;
      background: #ffffff;
      box-shadow: 1px 1px 5px rgba(7, 7, 7, 0.3);

      margin: 0 15px;

      &__header {
        @include align-center;
        justify-content: space-between;
      }

      &__logo {
        @include align-center;
        justify-content: center;
        background-color: map-get($colors, 'green');
        height: 80px;
        width: 80px;
        border-radius: 50%;
      }

      &__stars {
        @include align-center;
        gap: 5px;
      }

      h5 {
        margin: 30px 0 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: map-get($colors, 'heading');
      }

      span {
        display: block;
        margin: 20px 0;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #828282;
      }

      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        color: #000;

        height: 300px;
        overflow: scroll;
      }
    }
  }
}

.slick-slider {
  .slick-next,
  .slick-prev {
    width: 60px;
    height: 60px;
    background: #fff;

    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: -100px;
    cursor: pointer;

    &::before {
      content: '';
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 20px;
      height: 20px;
    }
    @media screen and (max-width: 768px) {
      top: -30px;
      width: 45px;
      height: 45px;
    }
  }

  .slick-next {
    right: 20px;

    @media screen and (max-width: 768px) {
      right: 0;
    }

    &::before {
      content: '';
      background-image: url('../../static/arrow-next.svg');
    }
  }
  .slick-prev {
    right: 100px;
    left: unset;

    &::before {
      content: '';
      background-image: url('../../static/arrow-prev.svg');
    }

    @media screen and (max-width: 768px) {
      right: auto;
      left: 0;
    }
  }
}
