@import '../../styles/helpers/index.scss';

.cta {
  margin: 130px 0 0;
  padding: 120px 0;
  background-image: url('../../static/cta_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .container {
    @include align-center;
    justify-content: space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }
  }

  .section-title {
    color: #fff;
    z-index: 1;

    &::before {
      background-image: url('../../static/services_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 27%;
      top: -15%;
    }
  }
}
