@import '../../styles/helpers/index.scss';

.contacts {
  margin: 120px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0 0;
  }

  .container {
    @include align-center;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > * {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        // width: auto;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & > div {
      padding: 30px 40px;
      @include align-center;
      background-color: #fff;

      font-weight: 600;
      font-size: 24px;
      line-height: 150%;

      color: map-get($colors, 'heading');

      @media screen and (max-width: $tablet-sm) {
        font-size: 18px;
        padding: 30px 20px;
      }

      img {
        margin: 0 30px 0 0;
      }
    }
  }

  form {
    background-color: map-get($colors, 'green');

    h4 {
      color: #fff;
    }

    input[type='submit'] {
      border-color: #fff;
    }
  }

  &__map {
    margin: 120px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 60px 0 0;
    }

    iframe {
      width: 100%;
      height: 500px;
      border: none;
      display: block;
    }
  }
}
