@import '../../styles/helpers/index.scss';

.book-your-move {
  margin: 130px 0 0;
  padding: 100px 0;

  background-image: url('../../static/book_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .section-title {
    color: #fff;
  }

  .links {
    margin: 60px 0 0;
    @include align-center;
    gap: 35px;

    font-weight: 600;
    font-size: 24px;
    line-height: 120%;

    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      align-items: flex-start;
    }

    span {
      a {
        color: #fff;
      }
    }
  }
}
