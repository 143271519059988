@import '../../styles/helpers/index.scss';

.satisfaction {
  margin: 75px 0 0;

  text-align: center;

  .section-title {
    &::before {
      background-image: url('../../static/satisfaction_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 17%;
      top: -15%;
    }
  }

  &__grid {
    margin: 45px 0 0;
    @include grid($columns: repeat(3, 1fr));

    & > div {
      @include scale;
      @include align-center;
      padding: 40px 30px;
      flex-direction: column;
      justify-content: center;
      background: #fff;
      box-shadow: 4px 4px 30px rgba(#777, 0.3);

      & > div {
        margin: 20px 0 0;
        @include align-center;
        gap: 20px;

        font-weight: 600;
        font-size: 40px;
        line-height: 40px;
        color: map-get($colors, 'heading');
      }
    }
  }
}
