@import '../../styles/helpers/index.scss';

.moving-services {
  margin: 130px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .section-title {
    text-align: center;

    &::before {
      background-image: url('../../static/about_ellipse.svg');

      width: 260px;
      height: 120px;

      top: -20%;
      left: 18%;
    }
  }

  &__grid {
    margin: 85px 0 0;
    @include grid($columns: repeat(3, 1fr));

    & > div {
      @include scale;
      padding: 60px 0;
      @include align-center;
      justify-content: center;
      flex-direction: column;
      background-color: map-get($colors, 'green');

      p {
        margin: 35px 0 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: #fff;
      }
    }
  }
}
