@import '../../styles/helpers/index.scss';

.why-we-small {
  margin: 140px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    &::before {
      background-image: url('../../static/why-we_ellipse.svg');

      width: 260px;
      height: 120px;

      left: -2%;
      top: -20%;
    }
  }

  &__grid {
    @include grid($columns: repeat(4, 1fr));
    margin: 70px 0 0;
  }

  &__card {
    padding: 50px 30px;
    @include align-center;
    justify-content: center;
    flex-direction: column;

    background-color: #ffffff;
    box-shadow: 4px 4px 30px rgba(#777, 0.3);

    text-align: center;

    @include scale;

    @media screen and (max-width: $tablet-sm) {
      padding: 30px;
    }

    span {
      margin: 20px 0 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      color: map-get($colors, 'heading');
    }
  }
}
