@import '../../styles/helpers/index.scss';

.pricing {
  margin: 190px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 70px 0 0;
  }

  .section-title {
    &::before {
      background-image: url('../../static/why-we_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 35%;
      top: -20%;
    }
  }

  &__grid {
    margin: 60px 0 0;
    @include grid($columns: repeat(3, 1fr));

    & > div {
      padding: 45px 0;
      box-shadow: 4px 4px 30px rgba(#777, 0.3);

      font-size: 18px;
      line-height: 120%;
      color: map-get($colors, 'heading');

      h5 {
        margin: 0 0 15px;
        padding: 0 30px;
        font-weight: 600;
        font-size: 30px;
        line-height: 120%;
        color: map-get($colors, 'green');
      }

      & > span {
        padding: 0 30px;
      }

      .rate {
        margin: 20px 0 0;
        padding: 20px 30px;
        background-color: map-get($colors, 'green');
        font-weight: 600;
        font-size: 60px;
        line-height: 120%;
        color: #fff;

        & > span {
          font-size: 40px;
          line-height: 120%;
        }
      }

      .description {
        margin: 35px 0 0;
        padding: 0 30px;
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
      }

      ul {
        margin: 30px 0 0;
        padding: 0 30px 0 45px;
        list-style-type: disc;
        color: map-get($colors, 'green');
        li {
          font-weight: 300;
          font-size: 18px;
          line-height: 200%;
          & > span {
            color: map-get($colors, 'heading');
          }
        }
      }
    }
  }
}
