@import '../../styles/helpers/index.scss';

form {
  padding: 30px 25px 50px;
  background: #ffffff;
  box-shadow: 4px 4px 30px rgba(7, 7, 7, 0.1);

  display: flex;
  flex-direction: column;
  gap: 20px;

  h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 116%;
    color: map-get($colors, 'heading');
  }

  input,
  textarea {
    width: 100%;
    padding: 0 20px;

    font-family: 'Poppins', sans-serif;

    border: 1px solid rgba(51, 51, 51, 0.4);

    &::placeholder {
      font-family: inherit;
    }
  }

  input {
    height: 50px;
    line-height: 50px;

    &[type='submit'] {
      max-width: 130px;
      border-color: map-get($colors, 'green');
      background-color: map-get($colors, 'green');
      color: #fff;
      cursor: pointer;

      transition: all 0.3s ease;

      &:hover {
        background-color: #fff;
        color: map-get($colors, 'green');
      }
    }
  }

  textarea {
    height: 120px;
    resize: none;
    padding: 20px;
  }
}

#book-now {
  form {
    max-width: 600px;
    margin: 60px auto;
  }
}
