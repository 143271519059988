@import '../../styles/helpers/index.scss';

.call-us {
  margin: 130px 0 0;
  padding: 120px 0;
  background-color: map-get($colors, 'heading');

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .container {
    display: flex;
    gap: 20px;
  }

  .section-title {
    color: #fff;
    white-space: nowrap;
    z-index: 1;

    font-size: 68px;

    @media screen and (max-width: $tablet-sm) {
      font-size: 32px;
    }

    &::before {
      background-image: url('../../static/call-us_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 22%;
      top: -15%;
    }
  }

  &__content {
    max-width: 50%;
    width: 100%;

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
      width: auto;
    }
  }

  &__media {
    max-width: 50%;
    width: 100%;
    background-image: url('../../static/estimate-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  .links {
    @include align-center;
    gap: 35px;
    margin: 55px 0 0;

    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      gap: 15px;
    }

    & > span {
      font-weight: 600;
      font-size: 24px;
      line-height: 200%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 14px;
      }
    }

    a {
      color: #fff;
    }
  }
}
