@import '../../styles/helpers/index.scss';

.counter {
  margin: 130px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  &__grid {
    @include grid($columns: repeat(3, 1fr));

    & > div {
      @include align-center;
      flex-direction: column;

      &:nth-of-type(2) {
        border-left: 2px solid map-get($colors, 'green');
        border-right: 2px solid map-get($colors, 'green');

        @media screen and (max-width: $tablet-sm) {
          border: none;
        }
      }

      span {
        display: block;
        margin: 25px 0 0;
        font-weight: 600;
        font-size: 60px;
        line-height: 120%;
        color: map-get($colors, 'green');
      }

      p {
        font-size: 24px;
        line-height: 150%;
        color: map-get($colors, 'heading');
      }
    }
  }
}
