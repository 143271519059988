@import '../../styles/helpers/index.scss';

.get-quote {
  margin: 130px 0 0;
  padding: 80px 0 70px;

  background-image: url('../../static/quote_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .container {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > * {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        width: unset;
      }
    }
  }

  .section-title {
    color: #fff;
    z-index: 1;

    &::before {
      background-image: url('../../static/quote_ellipse.svg');

      width: 260px;
      height: 120px;

      left: -7%;
      top: -4%;
    }

    @media screen and (max-width: $tablet-sm) {
      text-align: center;
      line-height: 150%;
    }
  }

  .api-form {
    background-color: transparent;
  }
}
