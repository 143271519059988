@import '../../styles/helpers/index.scss';

.posts-grid {
  margin: 170px 0 120px;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 60px;
  }

  &__categories {
    @include align-center;
    gap: 20px;

    &--item {
      padding: 15px 30px;

      border: 2px solid map-get($colors, 'green');
      color: map-get($colors, 'green');

      font-weight: 600;
      font-size: 16px;
      line-height: 120%;

      text-align: center;

      cursor: pointer;

      transition: all 0.3s ease;

      &:hover {
        background-color: map-get($colors, 'green');
        color: #fff;
      }

      &.active {
        background-color: map-get($colors, 'green');
        color: #fff;
      }
    }
  }

  &__list {
    margin: 60px 0 0;
    @include grid;
  }

  &__card {
    @include scale;
    display: block;
    box-shadow: 4px 4px 30px rgba(#777, 0.3);

    &--content {
      padding: 40px 30px;
    }

    &--image {
      img {
        max-height: 350px;
        width: 100%;
        object-fit: cover;
      }
    }

    &--meta {
      font-weight: 300;
      font-size: 16px;
      line-height: 155%;
      color: #828282;
    }

    h3 {
      margin: 30px 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;
      color: #070707;
    }
  }
}
