@import '../../styles/helpers/index.scss';

.post-content {
  margin: 120px 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 60px 0;
  }

  &__grid {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        width: auto;
      }
    }

    &--text {
      max-width: 70%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      .date {
        margin: 0 0 55px;
        display: block;
        font-weight: 300;
        font-size: 18px;
        line-height: 155%;
        color: #828282;
      }

      & > div {
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        color: #000;

        h2 {
          margin: 60px 0 30px;
          font-weight: 600;
          font-size: 40px;
          line-height: 130%;
        }

        h3 {
          font-weight: bold;
          margin: 0 0 15px;
        }

        p {
          margin: 0 0 15px;
        }

        a {
          color: #000;
          text-decoration: underline !important;
        }

        ul {
          list-style-type: disc;
        }

        ol {
          list-style-type: decimal;
        }

        ol,
        ul {
          padding-left: 15px;

          li {
            &:not(:last-of-type) {
              margin: 0 0 15px;
            }
          }
        }
      }
    }

    &--sidebar {
      max-width: 30%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }

      .recent-posts {
        margin: 125px 0 0;

        @media screen and (max-width: $tablet-sm) {
          margin: 60px 0 0;
        }

        h4 {
          font-weight: 600;
          font-size: 24px;
          line-height: 130%;
          color: #000;
          text-transform: uppercase;
        }

        ul {
          margin: 25px 0 0;

          li {
            position: relative;
            text-indent: 20px;

            &::before {
              content: '';

              background-image: url('../../static/list-arrow-double.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;

              width: 12px;
              height: 8px;

              position: absolute;
              left: 0;
              top: 10px;
            }

            a {
              font-weight: 300;
              font-size: 18px;
              line-height: 155%;
              color: #000;

              &:hover {
                text-decoration: underline !important;
              }
            }
          }
        }
      }
    }
  }

  h1 {
    margin: 0 0 80px;
    font-weight: 600;
    font-size: 64px;
    line-height: 120%;
    color: map-get($colors, 'heading');

    @media screen and (max-width: $tablet-sm) {
      font-size: 32px;
    }
  }
}
