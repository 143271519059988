@import '../../styles/helpers/index.scss';

.contact-us {
  margin: 130px 0 0;
  padding: 145px 0 40px;
  position: relative;
  z-index: 1;

  background-image: url('../../static/lp-ban-moving-company-business-plan.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
    padding: 70px 0 40px;
  }

  &::before {
    content: '';
    background-color: rgba(#000, 0.4);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .section-title {
    color: #fff;
    z-index: 1;

    @media screen and (max-width: $tablet-sm) {
      br {
        display: none;
      }
    }

    &::before {
      background-image: url('../../static/contact-us_ellipse.svg');

      width: 260px;
      height: 120px;

      right: 45%;
      top: -12%;
    }
  }

  p {
    margin: 10px 0 0;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    max-width: 580px;
  }

  .links {
    margin: 60px 0 0;
    @include align-center;
    gap: 35px;

    & > span {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
    }
  }

  .payments {
    margin: 25px 0 0;
    @include align-center;
    gap: 20px;
    justify-content: flex-end;

    @media screen and (max-width: $tablet-sm) {
      justify-content: center;
      margin: 30px 0 0;
    }
  }
}
