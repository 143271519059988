@import '../../styles/helpers/index.scss';

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease;

  background-color: #fff;

  &.shadow {
    box-shadow: 0 5px 15px 0 rgba(#000, 0.5);

    .top-line {
      padding: 0;
      opacity: 0;
      height: 0;
    }
  }

  .top-line {
    background-color: map-get($colors, 'heading');
    color: #fff;
    padding: 10px 0;
    text-align: right;

    font-weight: 600;
    font-size: 18px;
    line-height: 120%;

    height: auto;

    transition: all 0.3s ease;
  }

  .logo {
    display: block;

    @media screen and (max-width: $tablet-sm) {
      width: 30%;
    }

    img {
      width: 100%;
    }
  }

  .mobile-phone {
    display: none;
    font-weight: bold;
    color: map-get($colors, 'heading');
    position: relative;

    &::before {
      content: '';
      background-image: url('../../static/phone-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      width: 15px;
      height: 15px;

      position: absolute;
      left: -18px;
      top: 50%;
      transform: translateY(-50%);
    }

    @media screen and (max-width: $tablet-sm) {
      display: block;
    }
  }

  .navigation {
    padding: 15px 0;

    .container {
      @include align-center;
      justify-content: space-between;
    }

    nav {
      position: relative;
      @include align-center;
      gap: 30px;
      transition: left 0.3s ease;

      &.open {
        left: 0;
      }

      .close {
        position: absolute;
        top: 0;
        right: 15px;

        @media screen and (min-width: $tablet-sm) {
          display: none;
        }
      }

      @media screen and (max-width: $tablet-sm) {
        position: absolute;
        top: 0;
        left: -100%;

        width: 100vw;
        height: 100vh;

        flex-direction: column;
        background-color: #fff;

        justify-content: center;
      }
    }
  }

  .nav-menu {
    @include align-center;
    gap: 30px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    .active {
      color: map-get($colors, 'green') !important;
    }

    a,
    span {
      color: map-get($colors, 'heading');
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      cursor: pointer;

      &:hover {
        color: map-get($colors, 'green') !important;
      }
    }

    span {
      position: relative;
      display: block;

      &::before {
        content: '';
        background-image: url('../../static/nav-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        width: 10px;
        height: 6px;

        position: absolute;
        top: 50%;
        right: -15px;
        transform: translateY(-50%) rotate(180deg);
      }

      &:hover {
        &::before {
          background-image: url('../../static/nav-arrow_hover.svg');
          transform: translateY(-50%) rotate(0);
        }
      }

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    .nav-submenu {
      position: relative;
      padding: 15px 0;

      span {
        &.active {
          &::before {
            background-image: url('../../static/nav-arrow_hover.svg');
          }
        }
      }

      &:hover {
        span {
          color: map-get($colors, 'green') !important;
        }

        ul {
          display: block;
        }
      }

      @media screen and (max-width: $tablet-sm) {
        padding: 0;
      }

      ul {
        display: none;
        position: absolute;
        left: 0;
        top: 45px;

        border-top: 2px solid map-get($colors, 'green');
        background-color: #fff;

        padding: 30px;
        min-width: 290px;

        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

        @media screen and (max-width: $tablet-sm) {
          display: block;

          position: relative;
          border: none;
          box-shadow: none;
          top: 0;
          padding: 0;
          min-width: unset;
          text-align: center;

          li:first-of-type {
            a {
              @media screen and (max-width: $tablet-sm) {
                margin: 0 0 30px;
              }
            }
          }
        }

        a {
          color: #070707;
          font-size: 18px;
          line-height: 220%;

          @media screen and (max-width: $tablet-sm) {
            display: block;
            font-size: 24px;
            line-height: 100%;
          }
        }
      }
    }
  }

  .btn.outlined {
    color: map-get($colors, 'heading');

    position: relative;

    span {
      display: block;
      text-indent: 25px !important;
    }

    &::before {
      content: '';
      background-image: url('../../static/phone-icon.svg');
      width: 18px;
      height: 18px;

      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
    }
  }
}

.burger {
  display: none;
  width: 30px;

  span {
    display: block;
    border-bottom: 2px solid map-get($colors, 'heading');
    &:nth-of-type(2) {
      margin: 8px 0;
    }
  }

  @media screen and (max-width: $tablet-sm) {
    display: block;
  }
}

.close {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 40px;
  height: 40px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: (40px - 1px) / 2;
    left: 0;
    right: 0;
    height: 1px;
    background: map-get($colors, 'heading');
    border-radius: 1px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
