@import '../../styles/helpers/index.scss';

.btn {
  display: inline-block;
  padding: 15px 20px;

  font-weight: 600;
  font-size: 18px;
  border: 2px solid transparent;

  @media screen and (max-width: $tablet-sm) {
    font-size: 14px;
  }

  &.contained {
    color: #fff;
    background-color: map-get($colors, 'green');
  }

  &.outlined {
    border-color: map-get($colors, 'green');
    color: map-get($colors, 'green');
  }
}
