@import '../../styles/helpers/index.scss';

.areas {
  margin: 130px 0 0;
  padding: 80px 0;
  background-image: url('../../static/areas_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .container {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > * {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        width: auto;
      }
    }
  }

  .section-title {
    color: #fff;
    z-index: 1;

    &::before {
      background-image: url('../../static/areas_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 35%;
      top: -3%;
    }
  }

  ul {
    columns: 3;

    @media screen and (max-width: $tablet-sm) {
      columns: 2;
    }

    li {
      position: relative;
      font-weight: 300;
      font-size: 18px;
      line-height: 180%;
      text-indent: 20px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }

      &::before {
        content: '';

        background-image: url('../../static/list-arrow.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        width: 10px;
        height: 8px;

        position: absolute;
        top: 50%;
        left: 0;

        transform: translateY(-50%);
      }

      &:not(:last-of-type) {
        margin: 0 0 5px;
      }
    }
  }
}
