@import '../../styles/helpers/index.scss';

.services {
  margin: 130px 0 0;

  @media screen and (max-width: $tablet-sm) {
    margin: 65px 0 0;
  }

  .section-title {
    text-align: center;

    @media screen and (max-width: $tablet-sm) {
      text-align-last: left;
    }

    &::before {
      background-image: url('../../static/services_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 15%;
      top: -20%;
    }
  }

  &__list {
    margin: 60px 0 0;
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      margin: 30px 0 0;
    }

    &--card {
      width: 100%;
      background-color: map-get($colors, 'green');

      padding: 35px 0 70px;

      header {
        @include align-center;
        gap: 15px;

        img {
          @media screen and (max-width: $tablet-sm) {
            max-width: 150px !important;
          }
        }

        h4 {
          font-weight: 600;
          font-size: 40px;
          line-height: 120%;
          color: map-get($colors, 'heading');

          @media screen and (max-width: $tablet-sm) {
            font-size: 26px;
          }
        }
      }

      p {
        padding: 50px 40px 0;
        font-weight: 300;
        font-size: 18px;
        line-height: 150%;
        color: #000;
        min-height: 270px;

        @media screen and (max-width: $tablet-sm) {
          padding: 30px 40px 0;
        }
      }
    }

    &--links {
      margin: 45px 0 0;
      padding: 0 40px;

      display: flex;
      gap: 20px;

      a {
        display: inline-block;
        background-color: transparent !important;
        border: 2px solid #fff;
        color: #fff !important;

        &:last-of-type {
          background-color: #fff !important;
          color: map-get($colors, 'green') !important;
        }
      }
    }
  }
}
