@import '../../styles/helpers/index.scss';

.page-footer {
  padding: 85px 0 30px;
  background-color: map-get($colors, 'heading');
  color: #fff;

  .container {
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 30px;
    }

    & > div {
      width: 100%;
    }

    a {
      display: block;
      color: #fff;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  &__content {
    max-width: 50%;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
      text-align: center;
    }

    .logo {
      @media screen and (max-width: $tablet-sm) {
        img {
          margin: 0 auto;
          display: block;
        }
      }
    }

    p {
      max-width: 425px;
      font-weight: 300;
      font-size: 18px;
      line-height: 150%;

      margin: 45px 0 30px;
    }
  }

  &__contacts {
    max-width: 50%;
    text-align: right;

    @media screen and (max-width: $tablet-sm) {
      max-width: 100%;
      text-align: center;
    }

    .phone {
      font-weight: 600;
      font-size: 36px;
      line-height: 54px;
    }

    .email {
      margin: 50px 0;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0;
        font-size: 20px;
      }
    }

    & > div {
      font-weight: 300;
      font-size: 18px;
      line-height: 200%;

      &:not(:last-of-type) {
        margin: 0 0 30px;
      }

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
      }
    }
  }

  .underline {
    margin: 70px 0 0;
    padding: 30px 0 0;
    max-width: 100%;
    border-top: 1px solid #fff;
    text-align: center;

    @media screen and (max-width: $tablet-sm) {
      padding: 30px 0 60px;
      margin: 35px 0 0;
    }

    .legal-links {
      @include align-center;
      justify-content: center;
      gap: 15px;

      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;
      }
    }

    p {
      margin: 15px 0;
      font-weight: 300;
      font-size: 12px;
      line-height: 180%;
    }

    a[target='_blank'] {
      font-size: 12px;

      strong {
        font-weight: 700 !important;
      }

      &:hover {
        text-decoration: none !important;
        strong {
          text-decoration: underline;
        }
      }
    }
  }
}
