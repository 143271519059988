@import './helpers/variables.scss';
@import './helpers/mixins.scss';

html {
  height: 100% !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: #f4f4f4;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Poppins', sans-serif;

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  main {
    flex: 1 0 auto;
    padding: 145px 0 0;
    transition: all 0.3s ease;

    @media screen and (max-width: $tablet-sm) {
      padding: 109px 0 0;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }
}

.section-title {
  position: relative;
  font-weight: 600;
  font-size: 80px;
  line-height: 100%;

  color: map-get($colors, 'heading');

  @media screen and (max-width: $tablet-sm) {
    font-size: 36px;
  }

  &::before {
    content: '';

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    position: absolute;
    user-select: none;
    z-index: -1;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }
}

.data-protection-label {
  @include align-center;

  svg {
    width: 20px;
    margin: 0 15px 0 0;
  }
}

.api-form {
  .data-protection-label {
    margin: 15px 0 0;
    p {
      padding: 0;
    }
  }
}

.get-quote {
  .data-protection-label svg {
    fill: #fff;
  }
}

b,
strong {
  font-weight: bold;
}
