@import '../../styles/helpers/index.scss';

.why-we {
  margin: 75px 0 0;

  .section-title {
    &::before {
      background-image: url('../../static/why-we_ellipse.svg');

      width: 260px;
      height: 120px;

      left: 5%;
      top: -20%;
    }
  }

  &__content {
    margin: 55px 0 0;
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      margin: 30px 0 0;
      flex-direction: column;
    }

    & > div {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        width: auto;
      }
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 150%;
      color: #000;

      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }

    ul {
      margin: 40px 0 0;
      padding: 0 0 0 30px;
      font-weight: 600;
      font-size: 18px;
      line-height: 180%;
      color: map-get($colors, 'green');
      list-style-type: disc;

      @media screen and (max-width: $tablet-sm) {
        margin: 15px 0 0;
        padding: 0 0 0 20px;
      }
    }

    &--labels {
      @include grid($rows: repeat(2, 1fr));
    }

    &--card {
      padding: 0 30px;
      @include align-center;
      justify-content: center;
      flex-direction: column;

      background-color: #ffffff;
      box-shadow: 4px 4px 30px rgba(#777, 0.3);

      text-align: center;

      @include scale;

      @media screen and (max-width: $tablet-sm) {
        padding: 30px;
      }

      span {
        margin: 20px 0 0;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: map-get($colors, 'heading');
      }
    }
  }
}
